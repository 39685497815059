import $ from "jquery"
import AOS from "../../node_modules/aos/dist/aos.js"

window.$ = $
window.jQuery = $

//MENU MOBILE
$( "#hamburguer-menu" ).click(function() {
  $("#hamburguer-menu, #mobile-menu, #mobile-background").addClass("opened");
  $("body").css("overflow", "hidden");

});

$(window).click(function() {
  $("#hamburguer-menu, #mobile-menu, #mobile-background, .close-btn").removeClass("opened");
  $("body").css("overflow", "scroll");
});

$('#hamburguer-menu, #mobile-menu a').click(function(event){
  event.stopPropagation();
});

$( document ).ready(function() {
  $('#message-field').val("");
  AOS.init();
});

$(".goBackBtn").click(function(){
  window.history.back();
});

